
import { defineComponent, onMounted } from "vue";
import ClientPolicyTable from "@/components/individual/ClientPolicyTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "admin-policy-listing",
  components: {
    ClientPolicyTable
  },
  data() {
    return {
      policies: []
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Policy List", ["Administrator", "Policies"]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    //Set page title
    document.title = "Policy List | " + process.env.VUE_APP_TITLE;

    //Fetch individual client dashboard data
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(variables.SUPERADMIN_CLIENT_POLICIES_ROUTE)
        .then(({ data }) => {
          //Assign data to variable to bebpassed as a prop
          this.policies = data.data;
        })
        .catch(function (error) {
          variables.toastAlert(error.response.data.error, "error");
        });
    }
  }
});
